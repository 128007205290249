<template>
  <section class="profile">
    <header class="profile-header">
      <div class="top-section">
        <div class="profile-img-holder">
          <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" class="bi bi-person-circle profile-svg" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
          </svg>
        </div>
        <div class="profile-desc">
          <span class="profile-desc-inner">
            <span class="name">Kyra Samuel</span>
            <span class="bio">plant lover.</span>
            <span class="location">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="margin-right: 4px" class="bi bi-geo-alt-fill location-svg" viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
              </svg>Virginia Beach, VA</span>
          </span>
        </div>
      </div>
      <div class="bottom-section">
      </div>
    </header>
    <section class="collections">
      <div class="new-collection">
      </div>
      <div class="collections-list">
      </div>
    </section>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.profile {
  padding-top: calc(7rem + 2vh);
}
.light .profile-svg {
  fill: black;
}

.dark .profile-svg {
  fill: white;
}

.profile-header {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  border-bottom: 1px solid;
  padding-bottom: 20px;
  padding-top: calc(3rem + 2vh);
}

.light .profile-header,
.light .collections {
  border-bottom-color: black;
}

.dark .profile-header,
.dark .collections {
  border-bottom-color: white;
}

.top-section {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding-top: 20px;
}

.profile-img-holder {
  margin-left: 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  flex-flow: column nowrap;
}

.profile-desc,
.profile-desc-inner {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.profile-desc-inner {
  justify-content: center;
  width: 100%;
}

.profile-desc {
  text-align: left;
  padding-top: 28px;
  padding-left: 10px;
  width: 60%;
}


.bio {
  padding: 5px;
  width: 90%;
  height: 4rem;
}

.name {
  font-size: 14px;
  font-weight: bold;
}

.location {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  font-size: 12px;
  line-height: 12px;
}

.dark .location,
.dark .location-svg {
  color: darkgray;
  fill: darkgray;
}

.light .location {
  color: gray;
  fill: gray;
}

.collections {
  display: flex;
  flex-flow: row wrap;
  height: 15vh;
  border-bottom: 1px solid;
}

@media only screen and (max-width: 550px) {
  .profile-header {
    padding-top: 0;
  }

  .top-section {
    padding-top: 5px;
  }
}

@media only screen and (min-height: 600px) {
  .profile {
    padding-top: calc(3rem + 2vh);
  }
}

@media only screen and (min-width: 700px) {
  .top-section {
    justify-content: center;
    width: 85%;
    margin: 0 auto;
  }

  .profile-img-holder {
    width: 40%;
    align-items: flex-end;
    padding-right: 20px;
  }

  .bottom-section {
    width: 85%;
    margin: 0 auto;
  }
}
</style>